import { Filters } from "~/types/general";

// gettings filters params for requests from 'filters' object

export const useGetFiltersParams = (filters: Filters) => {
  const generalFilters = {};

  Object.values(filters).forEach((f) => {
    if (
      f.chosenOptions.length &&
      f.type !== "price" &&
      f.type !== "additional_types"
    ) {
      const values = f.chosenOptions.map((o) => o.value);

      generalFilters[`filter[${f.type.split("_")[0]}][]`] = values;
    }
  });

  if (filters.additional_types?.chosenOptions?.length) {
    const val = filters.additional_types.chosenOptions.map((o) => o.value);

    val.forEach((i) => {
      generalFilters[`filter[${i}]`] = true;
    });
  }

  // check if price filters applied
  const priceRange = filters.price?.isApplied
    ? {
        "filter[price_from]": filters.price.chosenOptions[0].value,
        "filter[price_to]": filters.price.chosenOptions[1].value,
      }
    : {};

  return {
    ...generalFilters,
    ...priceRange,
  };
};
